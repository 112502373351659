<template>
 
      <div>

        <div class="item__row item__ac">

            <h2>Услуги</h2>

            <v-btn
                small
                class="mx-2"
                fab
                dark
                color="#1f2b45"
                @click="chooseTypeFunction(1)"
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>

        </div>
                
        <div class="item__column  pa-4 mb-2 news__list" v-for="item in items" :key="item.id">

            <div  class="item__row item__ac">
                <div v-for="image in item.images"  :key="image.id" >
                    <img  :src="'https://api.kazaerospace.kz/'+image.path" />
                </div>  
            </div>
            <p class="mb-2">{{ item.name }}</p>
            <p class="mb-2">{{ item.name_kaz }}</p>
            <p class="mb-2">{{ item.name_eng }}</p>
                <!-- <p>{{ item.description }}</p> -->
            <div class="item__row item__ac">
                <v-btn
                    small
                    class="mx-2 mr-2"
                    fab
                    dark
                    color="#1f2b45"
                    @click="show(item)"
                    >
                    <v-icon dark>
                        mdi-pencil
                    </v-icon>
                </v-btn>

                <v-btn
                    v-if="me && me.role.role == 'admin'"
                    small
                    class="mx-2 mr-2"
                    fab
                    dark
                    color="#1f2b45"
                    @click="deleteItem(item.id)"
                    >
                    <v-icon dark>
                        mdi-trash-can-outline
                    </v-icon>
                </v-btn>
            </div>

            <v-divider></v-divider>

        </div>


        <v-dialog v-model="newsModal" width="750">
            <v-card class="pa-6">
                <v-form
                    @submit.prevent="callFunction()"
                    ref="form"
                    class="sign__page__block"
                >

                <h3 class="mb-4" v-if="type==1">Создать</h3>
                <h3 class="mb-4" v-else>Редактировать</h3>
                <div class="item__column">
                    <v-text-field
                        v-model="title"
                        label="Наименование"
                        required
                        outlined
                        class="input"
                        :rules="nameRules"
                    ></v-text-field>
                </div>
                <div class="item__column">
                    <v-text-field
                        v-model="title_kaz"
                        label="Наименование на каз"
                        required
                        outlined
                        class="input"
                        :rules="nameRules"
                    ></v-text-field>
                </div>
                <div class="item__column">
                    <v-text-field
                        v-model="title_eng"
                        label="Наименование на анг"
                        required
                        outlined
                        class="input"
                        :rules="nameRules"
                    ></v-text-field>
                </div>


                <div class="item__column">
                    <v-text-field
                        v-model="priority"
                        label="Приоритет"
                        required
                        outlined
                        class="input"
                        :rules="nameRules"
                        type="number"
                    ></v-text-field>
                </div>

                <div>
                     <v-file-input
                        chips
                        counter
                        multiple
                        show-size
                        small-chips
                        truncate-length="15"
                        v-model="files"
                        label="svg"
                    ></v-file-input>
                </div>

                <div class="item__column">
                    <div v-for="file in uploadedFiles" :key="file.id" class="item__row item__ac pointer mb-3">
                        <p class="mr-2 mb-0">{{file.path.split('/')[file.path.split('/').length-1]}}</p>
                        <i class="mdi mdi-trash-can-outline" @click="removeFile(file.id)"></i>
                    </div>
                </div>
                 <v-btn
                    type="submit"
                    depressed
                    color="primary"
                    >
                    Создать
                </v-btn>
                </v-form>
            </v-card>
        </v-dialog>

      </div>

</template>

<script>

export default {
  name: "News",
  data() {
    return {
        items: [],
        newsModal: false,
        title: '',
        title_kaz: '',
        title_eng: '',
        description: '',
            nameRules: [
                v => !!v || 'Заполните поле'
            ],
            descriptionRules: [
                v => !!v || 'Заполните поле'
            ],
        files: [],
        type: 0,
        newsId:'',
        me: null,
        id: null,
        priority: 0,
        uploadedFiles: []
    };
  },
  methods: {
        removeFile(fileId) {
            this.$axios({
                method: "delete",
                url:
                this.$API_URL +
                this.$API_VERSION +
                "services/file/"+fileId,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.$toast.open({
                    message: response.data.message,
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });
                this.fetch();
                this.newsModal = false;
            })
            .catch((error) => {
                console.warn(error);
            });
        },
        getUser() {
            this.$axios({
                method: "get",
                url:
                this.$API_URL +
                this.$API_VERSION +
                "me",
                headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.me = response.data;
            })
            .catch((error) => {
                console.warn(error);
            });
        },
      chooseTypeFunction(type) {
          this.type = type;
          this.newsModal = true;
      },
      callFunction() {
          this.type==1?this.create():this.update();
      },
      create() {
            let contractForm = new FormData();
            for (var i = 0; i < this.files.length; i++) {
                contractForm.append("images[]", this.files[i]);
            }
            contractForm.append("title", this.title);
            contractForm.append("title_kaz", this.title_kaz);
            contractForm.append("title_eng", this.title_eng);
            contractForm.append("priority", this.priority);
            this.$axios.post(this.$API_URL + this.$API_VERSION + "services", contractForm, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {

                this.title_kaz = null;
                this.title = null;
                this.title_eng = null;


                this.priority = null;
                 this.files = [];
                console.log(response);
                this.$toast.open({
                    message: "Успешно создано",
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });
               
                this.newsModal = false;
                this.type = 0;
                this.fetch();
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });
                }
            });
        },
        show(item) {
            this.newsModal = true;
            this.title = item.name;
            this.title_eng = item.name_eng;
            this.title_kaz = item.name_kaz;
            this.priority = item.priority;
            this.id = item.id;
            this.uploadedFiles = item.images;
        },
        deleteItem(id) {
            this.$axios({
            method: "delete",
            url:
                this.$API_URL +
                this.$API_VERSION +
                "services/"+id,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.title = response.data.title;
                this.description = response.data.description;
                this.fetch();
            })
            .catch((error) => {
            console.log(error);
            });
        },
        update() {
            this.$axios.put(this.$API_URL + this.$API_VERSION + "services/"+this.id, {
                title: this.title,
                title_kaz: this.title_kaz,
                title_eng: this.title_eng,
                priority: this.priority
            },{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                console.log(response);
                this.$toast.open({
                    message: "Успешно создано",
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });
                this.newsModal = false;
                this.title_kaz = null;
                this.title = null;
                this.title_eng = null;

                if(this.files.length>0)
                    this.uploadFiles();
                this.fetch();
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });
                
                }
            });
      },
      fetch() {
        this.$axios({
          method: "get",
          url:
            this.$API_URL +
            this.$API_VERSION +
            "services",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            this.items = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      },
      uploadFiles() {


            let contractForm = new FormData();
            for (var i = 0; i < this.files.length; i++) {
                contractForm.append("images[]", this.files[i]);
            }
            contractForm.append("id", this.id);
            this.$axios
                .post(this.$API_URL + this.$API_VERSION + "services/files/"+this.id, contractForm, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                console.log(response);

                this.files = [];
                this.newsModal = false;
                this.type = 0;
                this.fetch();
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });

                }
            });
        },
  },
  mounted() {
      this.fetch();
      this.getUser();
  },
  beforeMount() {

  },
  watch: {
      newsModal(val) {
          if(val==false) {
                this.files = [];
                this.title_kaz = null;
                this.title = null;
                this.title_eng = null;

                this.priority = '';
                this.id = null;
                this.uploadedFiles = [];
          }
      }

  },
};
</script>


<style lang="scss">
.news__list {
    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
}
</style>